
<template>
    <div>
        <CSDialog
            :dialogTitle=" commodityInfo.id ? '编辑商品' : '上架新商品'"
            dialogWidth="960px" :is-submitting="isSubmitting"
            :dialogVisible="editCommodityVisible"
            @onClose="editCommodityVisible = false"
            dialogConfirmBtnText="保存"
            @onConfirm="commitCommodityInfo"
        >
            <div
                slot="dialog-content"
                style="padding: 20px 30px; font-size: 24px;"
            >
                <div style="margin-bottom: 23px">
                    <label
                        style="width: 110px; text-align: right; margin-right: 40px; height: 24px; line-height: 24px; vertical-align: top;"
                    >
                        配图
                    </label>
                    <div style="display: inline-block;">
                        <template
                            v-for="(
                                src, index
                            ) in commodityInfo.photos"
                        >
                            <div
                                class="commodity-img delete"
                                :key="index"
                                @click="
                                    removeCommodityPhotos(index)
                                "
                            >
                                <img :src="src" />
                            </div>
                        </template>
                        <template
                            v-if="commodityInfo.photos.length < 5"
                        >
                            <div
                                class="upload-photo"
                                style="width: 100px; height: 100px"
                                @click="emitChoosePhoto"
                            >
                                <div class="text-center">
                                    <img
                                        src="@/assets/upload.png"
                                        alt=""
                                        style="margin: 5px auto 5px"
                                    />
                                    <p style="color: #999">上传照片</p>
                                </div>
                            </div>
                            <input
                                type="file"
                                class="file"
                                accept="images/*"
                                id="uploadOwnerPhoto"
                                hidden
                                @change="choosePhoto($event)"
                            />
                        </template>
                    </div>
                </div>
                <div style="margin-bottom: 23px">
                    <label style="width: 110px; text-align: right; margin-right: 40px;"
                    >
                        标题
                    </label>
                    <input
                        type="text"
                        v-model="commodityInfo.name"
                        style="width: 400px; height: 40px; border: 1px solid #999; border-radius: 4px; padding-left: 10px;"
                        placeholder="请输入"
                    />
                </div>
                <div style="margin-bottom: 15px">
                    <label style="width: 110px; text-align: right; margin-right: 40px;"
                    >
                        商品描述
                    </label>
                    <input
                        type="text"
                        v-model="commodityInfo.depict"
                        style="width: 400px; height: 40px; border: 1px solid #999; border-radius: 4px; padding-left: 10px;"
                        maxlength="40"
                        placeholder="请输入"
                    />
                </div>
                <div style="margin-bottom: 23px">
                    <label
                        style="width: 110px; text-align: right; margin-right: 40px; vertical-align: middle; margin-bottom: 0;"
                    >
                        原价
                    </label>
                    <div style="display: inline-block; vertical-align: middle;">
                        <div style="display: flex; align-items: center;">
                            <input
                                class="form-check-input"
                                v-model="
                                    hasOriginalPrice
                                "
                                type="radio"
                                name="haveOriginal"
                                id="noOriginal"
                                :value="false"
                                style="margin: 0;"
                            />
                            <label
                                class="form-check-label mr-4"
                                for="noOriginal"
                                style="margin: 0 10px;"
                            >
                                无
                            </label>
                            <input
                                class="form-check-input"
                                v-model="
                                    hasOriginalPrice
                                "
                                type="radio"
                                name="haveOriginal"
                                id="haveOriginal"
                                :value="true"
                                style="margin: 0;"
                            />
                            <label
                                class="form-check-label"
                                for="haveOriginal"
                                style="margin: 0 10px; "
                            >
                                有
                            </label>
                            <template v-if="hasOriginalPrice">
                                <input
                                    v-model="
                                        commodityInfo.originalPrice
                                    "
                                    style="margin-left: 20px; vertical-align: middle; width: 400px; height: 40px; border: 1px solid #999; border-radius: 4px; padding-left: 10px;"
                                    placeholder="请输入"
                                    type="number"
                                />
                            </template>
                        </div>
                    </div>
                </div>

                <div style="margin-bottom: 23px">
                    <label
                        style="width: 110px; text-align: right; margin-right: 40px;"
                    >
                        现价
                    </label
                    >
                    <input
                        v-model="commodityInfo.price"
                        placeholder="请输入"
                        style="width: 400px; height: 40px; border: 1px solid #999; border-radius: 4px; padding-left: 10px;"
                        type="number"
                    />
                </div>
                <div style="margin-bottom: 23px">
                    <label
                        style="width: 110px; margin-right: 40px; text-align: right;"
                    >
                        库存
                    </label>
                    <input
                        v-model="commodityInfo.stock"
                        type="number"
                        style="width: 400px; height: 40px; border: 1px solid #999; border-radius: 4px; padding-left: 10px;"
                        placeholder="请输入"
                    />
                </div>
                <div style="margin-bottom: 23px">
                    <label
                        style="width: 110px; margin-right: 40px; text-align: right; vertical-align: top; height: 24px; line-height: 24px;"
                    >
                        介绍
                    </label>
                    <div
                        style="width: 720px; display: inline-block;"
                    >
                        <WangEditor
                            name="wangEditor"
                            componentName="WangEditor"
                            parentComponent="editCommodityModal"
                        ></WangEditor>
                    </div>
                </div>
            </div>
        </CSDialog>
    </div>
</template>
<script>
import { addMallItemUrl, editMallItemUrl } from "@/requestUrl";
import WangEditor from "@/components/WangEditor.vue";
import CSDialog from "@/components/common/CSDialog";

export default {
    props: {},
    components: {
        WangEditor,
        CSDialog,
    },
    data() {
        return {
            hasOriginalPrice: false,
            isSubmitting: false,
            commodityInfo: {
                regionCode: this.$vc.getCurrentRegion().code,
                name: "",
                photos: [],
                content: "",
                depict: '',
                originalPrice: null, // 原价，单位分，选填
                price: null, // 售价，必填，单位分
                stock: null, // 库存，必填，需为正数
            },
            editCommodityVisible: false,
        };
    },

    mounted() {
        this.$vc.on(this.$route.path, "editCommodityModal", "changeEditor", this.changeEditor);
        this.$vc.on(this.$route.path, "editCommodityModal", "show", this.listenerShowModal);
    },
    methods: {
        editorCommodityValidate() {
            return this.$vc.validate.validate(
                {
                    commodityInfo: this.commodityInfo,
                },
                {
                    "commodityInfo.price": [
                        {
                            limit: "required",
                            param: "",
                            errInfo: "价格不能为空",
                        },
                    ],
                    "commodityInfo.name": [
                        {
                            limit: "required",
                            param: "",
                            errInfo: "标题不能为空",
                        },
                    ],
                    "commodityInfo.content": [
                        {
                            limit: "required",
                            param: "",
                            errInfo: "商品介绍不能为空",
                        },
                    ],
                    "commodityInfo.photos": [
                        {
                            limit: "minLength",
                            param: "1",
                            errInfo: "最少上传一张图",
                        },
                    ],
                    "commodityInfo.stock": [
                        {
                            limit: "required",
                            param: "",
                            errInfo: "库存不能为空",
                        },
                    ],
                }
            );
        },
        changeEditor(html) {
            this.commodityInfo.content = html;
        },
        /**
         * 监听弹窗显示，更新数据
         * @param {Object} commodityInfo 商品信息
         *  */
        listenerShowModal(commodityInfo) {
            this.editCommodityVisible = true;
            if(commodityInfo && typeof commodityInfo?.originalPrice == "undefined"){
                commodityInfo.originalPrice = null;
                this.hasOriginalPrice = false;

            }else{
                 this.hasOriginalPrice = true;
            }
            this.resetCommodityInfo(commodityInfo);
        },
        resetCommodityInfo(commodityInfo) {
            console.log(commodityInfo, '商品信息');
            if (commodityInfo == null || commodityInfo == "null") {
                this.commodityInfo = {
                    regionCode: this.$vc.getCurrentRegion().code,
                    name: "",
                    photos: [],
                    content: "",
                    originalPrice: null, // 原价，单位分，选填
                    price: null, // 售价，必填，单位分
                    stock: null, // 库存，必填，需为正数
                };
            } else {
                this.commodityInfo.id = "";
                for (let key in this.commodityInfo) {
                    switch (key) {
                        case "photos":
                            try {
                                this.commodityInfo[key] =
                                    JSON.parse(commodityInfo[key]) || [];
                            } catch (err) {
                                this.commodityInfo[key] = [];
                            }
                            break;
                        case "originalPrice":
                            // console.log(77,commodityInfo["originalPrice"]);
                            if(commodityInfo["originalPrice"]==null){
                                 this.hasOriginalPrice = false;
                            }else{
                                 this.hasOriginalPrice = true;
                            }
                        case "price":
                            this.commodityInfo[key] =
                                (commodityInfo[key] - 0);
                            // if (
                            //     commodityInfo["originalPrice"] ==
                            //     commodityInfo["price"]
                            // ) {
                            //     this.commodityInfo["originalPrice"] == null;
                            //     this.hasOriginalPrice = false;
                            // } else {
                            //     this.hasOriginalPrice = true;
                            //     //  this.hasOriginalPrice = false;
                            // }
                            break;
                        default:
                            this.commodityInfo[key] = commodityInfo[key];
                    }
                }
            }
            // setTimeout(() => {
            //     this.$vc.emit(this.$route.path,
            //         "wangEditor",
            //         "setWangEditorContent",
            //         this.commodityInfo.content
            //     );
            // }, 1000)
            this.$nextTick(()=>{
                 this.$vc.emit(this.$route.path,
                    "wangEditor",
                    "setWangEditorContent",
                    this.commodityInfo.content
                );
            })
        },
        emitChoosePhoto() {
            $("#uploadOwnerPhoto").trigger("click");
        },
       async choosePhoto(event) {
            var photoFiles = event.target.files;
            if (photoFiles && photoFiles.length > 0) {
                // 获取目前上传的文件
                var file = photoFiles[0]; // 文件大小校验的动作
                if (file.size > 1024 * 1024 * 2) {
                    this.$vc.toast("图片大小不能超过 2M!");
                    return false;
                }
                let formData = new FormData(); //新建FormData对象
                formData.append("files", photoFiles[0]);
                formData.append("bucketName", this.Constants.BUCKET_NAMES.IMG.name);
                formData.append("compress", "true");
                const photo = await this.Constants.uploadFileOne(formData);
                if (photo) {
                    this.commodityInfo.photos.push(this.Constants.BUCKET_NAMES.IMG.visitUrl + photo);
                }
                // var reader = new FileReader(); //新建FileReader对象
                // reader.readAsDataURL(file); //读取为base64
                // reader.onloadend = async (e) => {
                //     let imgSrc = await this.uploadPhoto(reader.result).catch(
                //         (err) => {
                //             this.$vc.toast("上传图片错误,请重新上传");
                //         }
                //     );
                //     if (imgSrc && imgSrc != "") {
                //         this.commodityInfo.photos.push(imgSrc);
                //     }
                //
                // };
                event.target.value = "";
            }
        },
        async uploadPhoto(base64) {
            return new Promise((reslove, reject) => {
                this.$vc.http.post(
                    "uploadImg",
                    "upload",
                    {
                        img: base64,
                    },
                    {
                        headres: {
                            "Content-Type": "application/json",
                        },
                    },
                    (resStr, res) => {
                        reslove(res.body.fileSaveName);
                    },
                    (errText, err) => {
                        reject(errText);
                    }
                );
            });
        },
        removeCommodityPhotos(index) {
            this.commodityInfo.photos.splice(index, 1);
        },
        // 提交商品信息
        commitCommodityInfo() {
            const { hasOriginalPrice, commodityInfo } = this;
            const {
                regionCode,
                name,
                photos,
                content,
                originalPrice,
                price,
                stock,
                id,
                depict,
            } = commodityInfo;
            if (!this.editorCommodityValidate()) {
                // this.$vc.toast(this.$vc.validate.errInfo);
                return;
            }
            if (this.hasOriginalPrice){
                if (originalPrice<0.01){
                    this.$vc.toast("原价不能小于0.01");
                    return;
                }
            }
            if (price){
                if (price<0.01){
                    this.$vc.toast("现价不能小于0.01");
                    return;
                }
            }

            this.isSubmitting = true;
            this.$fly
                .post(
                    this.commodityInfo.id ? editMallItemUrl : addMallItemUrl,
                    {
                        id,
                        regionCode,
                        name,
                        photos: JSON.stringify(photos),
                        content,
                        originalPrice: hasOriginalPrice
                            ? originalPrice
                            : "",
                        price: price,
                        stock,
                        depict,
                    }
                )
                .then((res) => {
                    if (res.code !== 0) {
                        return;
                    }
                    this.editCommodityVisible = false;
                    this.$vc.emit(this.$route.path,
                        "mallMange",
                        "commodityList",
                        "refresh",
                        null
                    );
                })
                .finally(() => this.isSubmitting = false);
            this.editCommodityVisible = false;
        },
    },
};
</script>
<style lang="stylus" scoped>
@import '../../public/common/static/css/component/mallManage.styl'
</style>
